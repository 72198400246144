import { EventEmitter } from 'events'

const ErrorEmitter = new EventEmitter()

// const API_ROOT_URL = window.location.hostname === 'nutritionpro.cz' ? 'https://nutritionpro.cz/api' : 'https://test.nutritionpro.cz/api'
const API_ROOT_URL = process.env.REACT_APP_API_BASE_URL

const createApiCall = ({
  method,
  headers = new Headers(),
  body,
  endpoint,
  authorization = localStorage.getItem('accessTokenKitchen'),
}) => {
  return {
    method,
    headers,
    body,
    endpoint: `${API_ROOT_URL}${endpoint}`,
    authorization,

    async fetch() {
      if (this.authorization) {
        this.headers.append('Authorization', `Bearer ${authorization}`)
      }

      this.headers.append('content-type', 'application/json')

      this.headers.append('Referrer-Policy', 'same-origin')

      const res = await fetch(`${API_ROOT_URL}${endpoint}`, {
        method: this.method,
        headers: this.headers,
        body: JSON.stringify(this.body),
      }).catch(error => {
        throw error
      })

      if (res.status === 401) {
        ErrorEmitter.emit('401')
      }

      return res
    },
  }
}

export default createApiCall

import createApiCall from './apicall'

// const port = window.location.hostname === 'localhost' ? '3005' : window.location.port
// const endpoint = `${window.location.protocol}//${window.location.hostname}:${port}/api`

export const loginUser = async body => {
  const options = {
    method: 'POST',
    endpoint: `/kitchen/sign_in`,
    body,
    authorization: false,
  }

  return createApiCall(options).fetch()
}

export const getUserData = async () => {
  const options = {
    method: 'GET',
    endpoint: `/kitchen/meta`,
  }

  return createApiCall(options).fetch()
}

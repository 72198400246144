import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
// import { saveAs } from 'file-saver'
import { getDocument } from 'services/document'
import actions from './actions'

export function* GET_DOCUMENT({ payload }) {
  const { doc, period, kitchen, start, end } = payload
  console.log('1', doc)
  yield put({
    type: 'document/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(getDocument, doc, period, kitchen, [start, end])
  // const blob = response.blob()
  if (response) {
    notification.success({
      message: 'Download document',
      description: 'You have successfully download!',
    })
  }
  // saveAs(blob, '1.xlsx')
  yield put({
    type: 'document/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DOCUMENT, GET_DOCUMENT),
  ])
}

import antdData from 'antd/lib/locale-provider/en_US'
import localeData from 'react-intl/locale-data/en'

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Type to search...',
  'topBar.buyNow': 'Buy Bundle $26',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',

  'menu.customers': 'Customers',
  'menu.orders': 'Orders',
  'menu.settings': 'Settings',
  'menu.promocodes': 'Promocodes',
  'menu.printDocuments': 'Print documents',

  'main.delete': 'Remove',
  'main.save': 'Save',
  'main.cancel': 'Cancel',
  'main.edit': 'Edit',
  'main.view': 'View',
  'main.add': 'Add',

  'erp.prot': 'Protein',
  'erp.fat': 'Fat',
  'erp.carb': 'Carbo',
  'erp.dish': 'Dish',
  'erp.meal': 'Meal',
  'erp.weightG': 'Weight (g)',
  'erp.cf': 'Cf',
  'erp.coefficient': 'Coefficient',
  'erp.group': 'Group',
  'erp.positions': 'Positions',
  'erp.netto': 'Netto',
  'erp.brutto': 'Brutto',
  'erp.unit': 'Unit',
  'erp.name': 'Name',
  'erp.mainGroup': 'Main group',


  'erp.ingredientGroup': 'Ingredient group',
  'erp.ingredient': 'Ingredient',
  'erp.techcard': 'Techcard',
  'erp.menuTemplate': 'Menu template',

  'erp.technologyСooking': 'The technology of cooking',
  'erp.semiproducts': 'Semiproducts',
  'erp.semiproduct': 'Semiproduct',

  'erp.ingredientGroups': 'Ingredient groups',
  'erp.ingredients': 'Ingredients',
  'erp.techcards': 'Techcards',
  'erp.menuTemplates': 'Menu templates',
  'erp.menuCalendar': 'Menu calendar',

  'erp.allGroups': 'All groups',
  'erp.allIngredients': 'All ingredients',
  'erp.allTechcards': 'All techcards',
  'erp.allTemplates': 'All templates',

  'erp.newGroup': 'New group',
  'erp.newIngredient': 'New ingredient',
  'erp.newTechcard': 'New techcard',
  'erp.newTemplate': 'New template',

  'erp.searchView': 'Search / View',

  'erp.title.createIngredientGroup': 'Creating an ingredient group',
  'erp.title.createIngredient': 'Creating an ingredient',
  'erp.title.createTechcard': 'Creating an techcard',
  'erp.title.createTemplate': 'Creating an menu template',

  'erp.title.editingIngredientGroup': 'Editing an ingredient group',
  'erp.title.editingIngredient': 'Editing an ingredient',
  'erp.title.editingTechcard': 'Editing an techcard',
  'erp.title.editingTemplate': 'Editing an menu template',


}

export default {
  locale: 'en-US',
  antdData,
  localeData,
  messages,
}

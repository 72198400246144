import createApiCall from './apicall'

// const port = window.location.hostname === 'localhost' ? '3005' : window.location.port
// const endpoint = `${window.location.protocol}//${window.location.hostname}:${port}/api`

export const getDocument = async (doc, period, kitchen, dates) => {
  const options = {
    method: 'POST',
    endpoint: `/erp/document/${doc}/${kitchen}/${period}?start=${dates[0]}&end=${dates[1]}`,
  }

  return createApiCall(options).fetch()
}

export const sendDocuments = async (doc, period, kitchen, dates, body) => {
  const options = {
    method: 'POST',
    endpoint: `/erp/document/${doc}/${kitchen}/${period}?start=${dates[0]}&end=${dates[1]}`,
    body,
  }

  return createApiCall(options).fetch()
}

export const showDocument = async () => {
  const options = {
    method: 'GET',
    endpoint: `/admin/meta`,
  }

  return createApiCall(options).fetch()
}

export const sendMenu = async (timestamp, kitchen) => {
  const options = {
    method: 'POST',
    endpoint: `/erp/menu/generate?timestamp=${timestamp}&kitchen=${kitchen}`,
  }

  return createApiCall(options).fetch()
}

export const sendWatchdog = async (dateMin, dateMax) => {
  const options = {
    method: 'POST',
    endpoint: `/erp/utils/watchdog/${dateMin}/${dateMax}`,
  }

  return createApiCall(options).fetch()
}

export const getAllowStatus = async (kitchen, timestamp) => {
  const options = {
    method: 'GET',
    endpoint: `/kitchen/allowDownload/${kitchen}/${timestamp}`,
  }

  return createApiCall(options).fetch()
}

import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import * as API from '../api/user'

const firebaseConfig = {
  apiKey: 'AIzaSyAE5G0RI2LwzwTBizhJbnRKIKbiXQIA1dY',
  authDomain: 'cleanui-72a42.firebaseapp.com',
  databaseURL: 'https://cleanui-72a42.firebaseio.com',
  projectId: 'cleanui-72a42',
  storageBucket: 'cleanui-72a42.appspot.com',
  messagingSenderId: '583382839121',
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
const firebaseAuth = firebase.auth

export default firebaseApp

export async function login(email, password) {
  return firebaseAuth()
    .signInWithEmailAndPassword(email, password)
    .then(() => true)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

async function loginReq(username, password) {
  const res = await API.loginUser({ username, password })
  if (res.ok) {
    const { accessToken } = await res.json()
    if (!accessToken) {
      console.log(accessToken)
      return 500
    }
    localStorage.setItem('accessTokenKitchen', accessToken)
    return 200
  }
  return res.status
}

export async function loginJWT(username, password) {
  return loginReq(username, password)
    .then(code => code)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = auth.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        resolve(user)
      }, reject)
    })
  }
  return getCurrentUser(firebaseAuth())
}

export async function currentAccountJWT() {
  function getCurrentUser() {
    return new Promise((resolve) => {
      API.getUserData().then(res => resolve(res.json()))
    })
  }
  return getCurrentUser()
}

export async function logout() {
  localStorage.removeItem('accessTokenKitchen')
  return true
}

// import React from 'react'
// import Authorize from 'components/LayoutComponents/Authorize'
// import { connect } from 'react-redux'
// import { FormattedMessage } from 'react-intl'

export async function getLeftMenuData() {
  return [
    {
      title: 'Finance',
      key: 'dbFinance',
      url: '/dashboard/finance',
      icon: 'icmn icmn-calculator',
      isAdmin: true,
    },
    {
      title: 'Objednávky',
      key: 'documentsPrint',
      url: '/documents',
      isAdmin: false,
    },
    {
      title: 'Ratings',
      icon: 'icmn icmn-star-half',
      key: 'ratings',
      url: '/ratings',
      isAdmin: false,
    },
  ]
}
export async function getTopMenuData() {
  return [
    {
      title: 'Finance',
      key: 'dbFinance',
      url: '/dashboard/finance',
      icon: 'icmn icmn-calculator',
      isAdmin: true,
    },
    {
      title: 'Objednávky',
      key: 'documentsPrint',
      url: '/documents',
      isAdmin: false,
    },
    {
      title: 'Ratings',
      icon: 'icmn icmn-star-half',
      key: 'ratings',
      url: '/ratings',
      isAdmin: false,
    },
  ]
}

import { notification } from 'antd'
import { saveAs } from 'file-saver'
import * as API from '../api/document'

export default API.getDocument
// async function callGetApi(doc, periodCode, period) {
//   const response = await API.getDocument(doc, periodCode, period)
    
//   if (response.ok) {
//     const blob = await response.blob()
//     // let name = doc
//     // switch (doc) {
//     //   case 'ingredient': {
//     //     name = '1 - Suroviny'
//     //     break
//     //   }
//     //   case 'production': {
//     //     name = '2 - Objednavka na vyrobu'
//     //     break
//     //   }
//     //   case 'techcards': {
//     //     name = '3 - Technicke Karty'
//     //     break
//     //   }
//     //   case 'subTechcards': {
//     //     name = '4 - Polotovary'
//     //     break
//     //   }
//     //   case 'packing': {
//     //     name = '5 - Baleni'
//     //     break
//     //   }
//     //   case 'picking': {
//     //     name = '6 - Kompletace'
//     //     break
//     //   }
//     //   default: {
//     //     console.log('Error: Invalid document name')
//     //     break
//     //   }
//     // }

//     return blob
//   }
//   return false
// }

export async function getDocument(doc, periodCode, kitchen = 'kitchen', period = []) {
  await API.getDocument(doc, periodCode, kitchen, period)
  .then(async resp => {
    const blob = await resp.blob()
    const filename = resp.headers.get('Filename')
    saveAs(blob, `${filename}`)
    return true
  })
  .catch(error => {
    notification.warning({
      message: error.code,
      description: error.message,
    })
  })
  return false
}
